<template>
  <div v-show="isAllowed" class="btn btn-primary--inverse" @click="previewDigitalAgreement">
    <img src="@/assets/img/icons/document.svg" />
    <span>{{ buttonName }}</span>
  </div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  name: 'preview-agreement-btn',
  props: {
    allowedActions: {
      required: true,
      validator: value => {
        if (value !== null) {
          return value.constructor.name === 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    listing: {
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'PREVIEW_AGREEMENT',
      actionType: null,
      digitalSignPreview: null,
    };
  },
  watch: {
    async isAllowed(val) {
      if (val) {
        await this.getDigitalSignPreview();
      } else {
        this.digitalSignPreview = null;
      }
    },
  },
  mounted() {
    if (this.isAllowed) {
      this.getDigitalSignPreview();
    } else {
      this.digitalSignPreview = null;
    }
  },
  methods: {
    async getDigitalSignPreview() {
      let result = null;
      if (this.listing.type === 'R') {
        result = await this.$store.dispatch(
          'v2/booking/acceptBookingRequest/previewDigitalAgreement',
          {
            rent_transaction_uuid: this.applicationUuid,
          },
        );
      } else if (this.listing.type === 'S') {
        this.$store.commit('sellTransaction/SET_SELL_TRANSACTION_UUID', this.applicationUuid);
        result = await this.$store.dispatch('sellTransaction/getFkpTemplate', {});
      }
      if (result && result.template) {
        this.digitalSignPreview = result.template;
      }
    },
    previewDigitalAgreement() {
      this.$modal.show('preview-digital-agreement-modal', {
        digitalSignPreview: this.digitalSignPreview,
        type: 'digital-agreement',
      });
    },
  },
};
</script>

<style scoped></style>
